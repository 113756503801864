import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import Loader from "components/Shared/Loader";
import { ThemeProvider } from "layouts/ThemeLayoutContext";
import { Button } from "reactstrap";
import { Provider } from "react-redux";
import store from "redux/store";
import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";
import { lazyWithRetry } from "utils/hooks";
import { BrowserTracing } from "@sentry/tracing";
import {
  connectorsForWallets,
  lightTheme,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import { metaMaskWallet } from "@rainbow-me/rainbowkit/wallets";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { publicProvider } from "wagmi/providers/public";
import { skaleRazor } from "@wagmi/core/chains";
import "@rainbow-me/rainbowkit/styles.css";

const history = createBrowserHistory();

const DashboardLayout = lazy(() =>
  lazyWithRetry(() => import("layouts/Dashboard.js"))
);
const SentryRoute = Sentry.withSentryRouting(Route);

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  cache: new InMemoryCache(),
});

const location = window.location.hostname;
location !== "localhost" &&
  Sentry.init({
    dsn: "https://d062a5a66bf74828955c93289b59cf4e@o1070194.ingest.sentry.io/6065829",
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new BrowserTracing(),
      new Sentry.Integrations.Breadcrumbs({ console: false }),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: true,
      }),
    ],
    tracesSampleRate: 1.0,
    tracingOrigins: ["staging.razorscan.io", "razorscan.io"],
    routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    environment: location === "staging.razorscan.io" ? "dev" : "production",
  });

const ErrorFallback = (error, componentStack, resetError) => {
  console.error("Error : ", error.toString());
  return (
    <div className="error-fallback" role="alert">
      <a className="mb-4 error-txt" href="/">
        Something went wrong. Try reloading the page.
      </a>
      <Button
        color="primary"
        className="m-auto error-btn"
        onClick={() => {
          resetError();
          window.location.href = "/";
        }}
      >
        Back to Home Page <i className="fas fa-arrow-left" />
      </Button>
      <div className="page-rzr-loader">
        <Loader type="line-scale-pulse-out" />
      </div>
    </div>
  );
};

const { chains, provider } = configureChains(
  [skaleRazor],
  [
    jsonRpcProvider({
      rpc: () => ({ http: skaleRazor.rpcUrls.default.http[0] }),
    }),
    publicProvider(),
  ],
  { pollingInterval: 10_000 }
);

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      //   injectedWallet({ chains }),
      //   rainbowWallet({ projectId: "Razorscan", chains }),
      metaMaskWallet({ projectId: "Razorscan", chains }),
      //   coinbaseWallet({ chains, appName: "RazorScan" }),
      //   ledgerWallet({
      //     projectId: "Razorscan",
      //     chains,
      //   }),
    ],
  },
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

if ("serviceWorker" in navigator) {
  window.addEventListener("load", function () {
    navigator.serviceWorker
      .register("/serviceWorker.js")
      .then(function (registration) {
        console.log("Service Worker is registered", registration);
      })
      .catch(function (error) {
        console.log("Service Worker registration failed:", error);
      });
  });
}

(async () => {
  ReactDOM.render(
    <ApolloProvider client={client}>
      <Sentry.ErrorBoundary
        fallback={({ error, componentStack, resetError }) =>
          ErrorFallback(error, componentStack, resetError)
        }
      >
        <WagmiConfig client={wagmiClient}>
          <RainbowKitProvider
            chains={chains}
            theme={lightTheme({
              accentColor: "#5e72e4",
            })}
            showRecentTransactions={true}
            modalSize="compact"
          >
            <Provider store={store}>
              <ThemeProvider>
                <BrowserRouter>
                  <Suspense
                    fallback={
                      <div className="page-rzr-loader">
                        <Loader type="line-scale-pulse-out" />
                      </div>
                    }
                  >
                    <Switch>
                      <SentryRoute
                        path="/"
                        render={(props) => <DashboardLayout {...props} />}
                      />
                    </Switch>
                  </Suspense>
                </BrowserRouter>
              </ThemeProvider>
            </Provider>
          </RainbowKitProvider>
        </WagmiConfig>
      </Sentry.ErrorBoundary>
    </ApolloProvider>,
    document.getElementById("root")
  );
})();
