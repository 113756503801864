import { useEffect, useRef, useMemo } from "react";
import { useLocation } from "react-router-dom";

// let localData = [];

// export const useValidators = () => {
//   const [data, setData] = useState(localData);

//   useEffect(() => {
//     if (data.length === 0) {
//       axios.defaults.timeout = 800;
//       axios
//         .get(
//           "https://raw.githubusercontent.com/razor-network/validators/main/chains/razorSChain/validatorList.json"
//         )
//         .then((res) => {
//           setData(res?.data?.validators);
//           localData = res?.data?.validators;
//         })
//         .catch((e) => {
//           console.log("error fetching verified validators", e);
//           setData([]);
//         });
//     }
//   }, []);

//   return data;
// };

// export const useEagerConnect = () => {
//   const { activate, active } = useWeb3React();

//   const [tried, setTried] = useState(false);

//   useEffect(() => {
//     injected.isAuthorized().then((isAuthorized) => {
//       if (isAuthorized) {
//         activate(injected, undefined, true).catch(() => {
//           setTried(true);
//         });
//       } else {
//         setTried(true);
//       }
//     });
//   }, []); // intentionally only running on mount (make sure it's only mounted once :));

//   // if the connection worked, wait until we get confirmation of that to flip the flag
//   useEffect(() => {
//     if (!tried && active) {
//       setTried(true);
//     }
//   }, [tried, active]);

//   return tried;
// };

// export const useInactiveListener = (suppress = false) => {
//   const { active, error, activate } = useWeb3React();

//   useEffect(() => {
//     const { ethereum } = window;
//     if (ethereum && ethereum.on && !active && !error && !suppress) {
//       const handleConnect = () => {
//         console.log("Handling 'connect' event");
//         activate(injected);
//       };
//       const handleChainChanged = (chainId) => {
//         console.log("Handling 'chainChanged' event with payload", chainId);
//         activate(injected);
//       };
//       const handleAccountsChanged = (accounts) => {
//         console.log("Handling 'accountsChanged' event with payload", accounts);
//         if (accounts.length > 0) {
//           activate(injected);
//         }
//       };
//       // eslint-disable-next-line no-unused-vars
//       const handleNetworkChanged = (networkId) => {
//         console.log("Handling 'networkChanged' event with payload", networkId);
//         activate(injected);
//       };

//       ethereum.on("connect", handleConnect);
//       ethereum.on("chainChanged", handleChainChanged);
//       ethereum.on("accountsChanged", handleAccountsChanged);
//       // ethereum.on("chainChanged", handleNetworkChanged);

//       return () => {
//         if (ethereum.removeListener) {
//           ethereum.removeListener("connect", handleConnect);
//           ethereum.removeListener("chainChanged", handleChainChanged);
//           ethereum.removeListener("accountsChanged", handleAccountsChanged);
//           // ethereum.removeListener("chainChanged", handleNetworkChanged);
//         }
//       };
//     }
//   }, [active, error, suppress, activate]);
// };

export const useTraceUpdate = (props) => {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log("Changed props:", changedProps);
    }
    prev.current = props;
  });
};

export const useQueryParam = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const lazyWithRetry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          console.log(retriesLeft, "retriesLeft");
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          lazyWithRetry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};
